<template>
  <div
    :class="{
      'z-30 top-0 h-1/2 pt-12 md:left-3 md:bottom-3 md:top-auto rounded-none' : chatState === 'minified',
      'z-20 top-0 ' : chatState === 'fullscreen',
      'z-20 bottom-0 shadow-2xl shadow-slate-900' : ['hidden', 'disabled', 'embedded'].includes(chatState) || !chatState,
      'fixed w-full left-0 bg-slate-800 rounded-xl overflow-hidden md:w-[400px] 3xl:w-[450px] 4xl:w-[550px] md:left-3 md:bottom-3 md:top-auto md:h-auto md:pt-0 md:shadow-2xl md:shadow-slate-900 md:rounded-xl shadow-md shadow-black' : displayMode === 'floating',
      'w-full overflow-hidden' : displayMode === 'embedded',
    }"
  >
    <transition name="slidedown-fade-fast" mode="out-in">
      <div
        v-if="showLeaderboard"
        :key="`key-${gameSelected?.id}`"
        class="relative bg-slate-700"
        :class="[ ['fullscreen', 'minified'].includes(chatState) ? 'hidden md:block' : '' ]"
      >
        <LeaderboardCoreMinigame
          :tournament="gameSelected"
          :polling-block="leaderboardPollingBlock"
          :trigger-update="leaderboardTriggerUpdate"
        />
      </div>
    </transition>

    <div
      class="relative h-full"
      :class="[
        mobileChatEnabled ? 'md:pb-[66.8%]' : 'pb-[66.8%]',
      ]"
    >
      <iframe
        id="Iframe"
        ref="Iframe"
        class="w-full transition-all duration-200 translate-x-0 bg-slate-600 absolute left-0 top-0"
        :class="[
          mobileChatEnabled ? 'h-full md:h-auto' : 'h-full',
          chatState === 'fullscreen' && mobileChatEnabled ? 'opacity-0' : 'opacity-100'
        ]"
        :src="gameUrl"
      />

      <div
        class="absolute z-20 top-2 right-2 flex items-center justify-end transition-all duration-500"
        :class="mobileChatEnabled ? 'top-16' : 'top-2'"
      >
        <ButtonController
          v-show="!gameReady && displayMode === 'floating'"
          icon-class="icon-ico-x"
          icon-size="text-xs"
          title="Close"
          @click="close"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useMinigamesStore } from '@/store/minigames';
import { useUiStore } from '@/store/ui';
import { useBankingStore } from '@/store/banking';
import { useAuthStore } from '@/store/auth';

defineProps({
  displayMode: {
    type: String,
    required: true,
  },
});

const uiStore = useUiStore();
const {
  chatState,
  showErrorModal,
  errorModalType,
} = storeToRefs(uiStore);
const {
  openWalletModal,
} = uiStore;

const authStore = useAuthStore();
const {
  isUserLogged,
} = storeToRefs(authStore);

const bankingStore = useBankingStore();
const {
  balanceUpdateDuration,
  balanceUpdateEnabled,
} = storeToRefs(bankingStore);
const {
  enableBalanceUpdate,
  loadWallet,
} = bankingStore;

const minigamesStore = useMinigamesStore();
const {
  gameSelected,
  entryData,
} = storeToRefs(minigamesStore);
const {
  closeGame,
  loadTournaments,
} = minigamesStore;

const { $gtmCustomEvent, } = useNuxtApp();

const mq = inject('mq');
const soundEnabled = ref(false);
const gameReady = ref(false);
const infoPanelOpen = ref(false);
const showLeaderboard = ref(false);
const leaderboardPollingBlock = ref(false);
const leaderboardTriggerUpdate = ref(true);
const Iframe = ref();

const mobileChatEnabled = computed(() => !!(['fullscreen', 'minified',].includes(chatState.value) && !mq.mdPlus));
const gameUrl = computed(() => !entryData.value ? null : entryData.value?.location);

function close() {
  if (infoPanelOpen.value) {
    infoPanelOpen.value = false;
    postMessage('INFO_CLOSE');
  } else {
    gameReady.value = false;
    showLeaderboard.value = false;
    loadWallet();
    closeGame();
  }
}

function postMessage(message) {
  Iframe.value.contentWindow.postMessage(message, '*');
  $gtmCustomEvent({ event: 'game', action: message.toLowerCase(), gameId: gameSelected.value?.id, });
}

function receiveMessage(event) {
  const { data, } = event;

  if (!data.message) { return; }

  switch (data.message) {
    case 'READY':
      gameReady.value = true;
      loadWallet();
      break;
    case 'ROUND_START':
      enableBalanceUpdate(false);
      leaderboardPollingBlock.value = true;
      break;
    case 'ROUND_COMPLETE':
      // enableBalanceUpdate(true);
      leaderboardPollingBlock.value = false;
      leaderboardTriggerUpdate.value = !leaderboardTriggerUpdate.value;
      break;
    case 'BALANCE_UPDATE':
      enableBalanceUpdate(true);
      balanceUpdateDuration.value = data.duration;
      leaderboardPollingBlock.value = false;
      loadWallet();
      break;
    case 'INFO_CLOSE':
      infoPanelOpen.value = false;
      break;
    case 'CLOSE':
      close();
      break;
    case 'SOUND_ENABLED':
      soundEnabled.value = true;
      break;
    case 'SOUND_DISABLED':
      soundEnabled.value = false;
      break;
    case 'GAME_ENDED':
      loadTournaments();
      break;
    case 'ERROR':
      loadWallet();
      loadTournaments();
      break;
    case 'BET_COMPLETE':
      leaderboardTriggerUpdate.value = !leaderboardTriggerUpdate.value;
      if (!balanceUpdateEnabled.value) { return; }
      loadWallet();
      break;
    case 'BET_ERROR':
      loadWallet();
      break;
    case 'OPEN_URL':
      if (data?.url) { navigateTo(data.url); }
      break;
    case 'OPEN_WALLET':
      openWalletModal('deposit');
      break;
    case 'SWITCH_TO_FULLSCREEN': {
      const slug = gameSelected.value?.game?.slug;
      if (slug) {
        close();
        return navigateTo(`/minigames/${slug}`);
      } else {
        errorModalType.value = 'General';
        showErrorModal.value = true;
      }
      break;
    }
  }
}

function checkLeaderboardDisplay() {
  if (gameSelected.value) {
    const { metadata, leaderboardId, } = gameSelected.value;
    showLeaderboard.value = !!((metadata?.leaderboardScheduleName || leaderboardId));
  }
}

watch(
  () => gameSelected.value,
  (newVal, oldVal) => {
    if (newVal?.id !== oldVal?.id) {
      showLeaderboard.value = false;
      gameReady.value = false;
      leaderboardPollingBlock.value = false;
      checkLeaderboardDisplay();
    }
  }
);

watch(
  () => isUserLogged.value,
  (newVal) => {
    if (!newVal) {
      infoPanelOpen.value = false;
      close();
    }
  }
);

onMounted(() => {
  window.addEventListener('message', receiveMessage);
  checkLeaderboardDisplay();
});

onUnmounted(() => {
  window.removeEventListener('message', receiveMessage);
  enableBalanceUpdate(true);
});
</script>
